// See config/_common.scss for the $width-locker-bank-standard setting
$width-locker-bank-narrow: $width-locker-bank-standard / 2;
$width-locker-bank-wide: $width-locker-bank-standard * 1.5;
$width-locker-bank-half: $width-locker-bank-standard / 2;

// Locker Details
//
// Each column is equal to the height of 15 small boxes
// A medium box is equal to the height of 2 small boxes
// A large box is equal to the height of 5 small boxes
// A console is equal to the height of 5 small boxes
//
// Height in percentages:
// A small box = 6.66667%
// A medium box = 13.33333%
// A large box = A console = 33.33333%
// A halfsize box = 50%

// Locker Form Styles
.console-options {
    width: 100%;
    margin-bottom: 0 !important;
}
.console-chosen-toggle-container {
    margin-top: 10px;
}

// jQuery Styles
.hide-templates, .add-edit-locker:not(.edit-locker) .console-chosen-toggle-container {
    display: none;
}

a.add-bank {
    margin-top: 5px;
}

// Locker Containers
#locker-template-container, .locker-template-container {
    margin-left: auto;
    margin-right: auto;
}

.locker-template-container {
    margin-top: 20px;
}

#locker-container > div.locker-wrapper {
    width: 100%;
}

.locker-wrapper > div.locker.locker-template-container {
    width: 100%;
}

// Titles and Labels
.locker-title {
    font-weight: bold;
}

.compressor-title,
.supercolumn-title {
    position: absolute;
    width: 100%;
    text-align: center;
    color: $color-primary-inverse;
}

.supercolumn-title {
    top: 15px;
}

// Bank Sizing
.bank {
    width: $width-locker-bank-standard;
}

.bank-narrow {
    width: $width-locker-bank-narrow;
}

.bank-wide {
    width: $width-locker-bank-wide;
}

.bank-half {
    width: $width-locker-bank-half;
}

@media only screen and (max-width: 767px) {
    .bank {
        width: $width-locker-bank-standard / 2;
    }

    .bank-narrow {
        width: $width-locker-bank-narrow / 2;
    }

    .bank-wide {
        width: $width-locker-bank-wide / 2;
    }

    .bank-half {
        width: $width-locker-bank-half / 2;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .bank {
        width: $width-locker-bank-standard * 0.8;
    }

    .bank-narrow {
        width: $width-locker-bank-narrow * 0.8;
    }

    .bank-wide {
        width: $width-locker-bank-wide * 0.8;
    }

    .bank-half {
        width: $width-locker-bank-half * 0.8;
    }
}

// Banks
.bank {
    border: 2px solid $color-secondary;
    height: $height-locker-tower;
    box-sizing: border-box;
    display: inline-block;
}

.bank > .column {
    height: 100%;
    width: 50%;
    float: left;
}

.bank-narrow > .column {
    width: 100%;
}

.bank-narrow.two-column > .column {
    width: 50%;
}

.bank-wide > .column {
    width: 33%;
}

.bank > .column.supercolumn,
.bank > .compressor {
    width: 100%;
    position: relative;
}

.bank-wide > .column.supercolumn {
    width: 67%;
}

.bank > .column,
.bank > .column > .box,
.bank > .column > .console,
.bank > .compressor {
    max-width: 100%;
    box-sizing: border-box;
}

// Box & Console Heights
.bank > .column > .box.small {
    height: 6.66667%;
}

.bank > .column > .box.medium,
.compressor {
    height: 13.33333%;
}

.bank > .column > .box.refrigerated {
    height: 21.66667%;
}

.bank > .column > .box.fresh {
    height: 28.88889%;
}

.bank > .column > .box.quartersize {
    height: 25%;
}

.bank > .column > .box.large,
.bank > .column > .console {
    height: 33.33333%;
}

.bank-locker-home > .column > .console {
    height: 47%;
}

.bank > .column > .box.outdoor-large {
    height: 40%;
}

.bank > .column > .box.halfsize {
    height: 50%;
}

.bank > .column > .console-full-height,
.bank > .column > .box.supersize {
    height: 100%;
}

.bank-wide > .column > .console {
    height: 100%;
}

.bank.keba>.column {
    &>.console {
        height: 30%;
    }

    &>.console-full-height {
        height: 100%;
    }

    &>.box.small,
    &>.box.s-small {
        height: 5%;
    }

    &>.box.medium {
        height: 10%;
    }

    &>.box.r-medium {
        height: 25%;
    }

    &>.box.large {
        height: 20%;
    }

    &>.box.x-large {
        height: 40%;
    }

    &>.box.xx-large {
        height: 65%;
    }
}

// Box Colors
.box,
.compressor,
.console {
    background-color: $color-muted;
}

.console {
    background-image: url("/img/themes/" + $theme-base-directory + "/console.png");
    background-repeat: no-repeat;
    background-position:  center center;
}

// Box Number Styles
.bank > .column > .box,
.bank > .column > .console,
.bank > .compressor {
    text-align: center;
    color: $color-primary-inverse;
    font-weight: 100;
    font-size: 12px;
    border: 1px solid $color-primary-inverse;
}
.bank > .column > .box > .box-number {
    margin: 0;
    padding: 0;
    font-weight: bold;
}

// For Modern Browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
.cf:before,
.cf:after {
    content: " "; // 1
    display: table; // 2
}

.cf:after {
    clear: both;
}

// Locker Settings on Property Page
.accordion-data {
    position: absolute;
    top: 12px;
    right: 25px;

    button.btn-xs {
        position: relative;
        top: -2px;
    }
}

// Background for unused boxes
.box.unused-box {
    background-image: url("/img/themes/" + $theme-base-directory + "/stripes.png");
    background-repeat: no-repeat;
    background-size: cover;
}

#modal-see-code .panel-primary .panel-heading {
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
}

#modal-see-code {
    max-width: 400px;
}

#modal-see-code .form-group {
    display: flex;
    align-items: center;
    padding: 0 26px;
}

#modal-see-code .form-group label {
    flex-basis: 100%;
    text-align: right;
    padding-right: 34px;
}

.show-more {
    display: flex;
    align-items: center;
    padding: 12px 0px 0px 0px;
}

a.show-more-btn i {
    font-size: 20px;
    cursor: pointer;
    color: #333;
    padding-left: 10px;
}

.pm-access-code {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 32px;
}
