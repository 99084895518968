// Profile Menu
.userbox {
    margin: 6px 16px 0 0;

    &.open {
        .dropdown-menu {
            top: -6px;
            z-index: 992 !important;

            a:hover {
                background-color: $color-primary;
            }

            i {
                display: inline-block;
                position: relative;
                top: -1px;
                width: 18px;
                text-align: center;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .userbox .name, .userbox .role {
        max-width: $sidebar-left-xs-full-width;
    }

    .userbox.show .dropdown-menu {
        width: auto;
    }
}

.profile-primary {
    background-color: $color-primary !important;
}

.profile-secondary {
    background-color: $color-secondary !important;
}

.profile-tertiary {
    background-color: $color-tertiary !important;
}

.profile-quaternary {
    background-color: $color-quaternary !important;
}

@media only screen and (min-width: 768px) and (max-width: $screen-md-max) {
    html.fixed .page-header {
        left: $sidebar-left-xs-full-width;
    }

    html.fixed .content-body {
        margin-left: $sidebar-left-xs-full-width;
    }
}

figure {
    width: 40px;
}

.initials-icon {
    text-align: center;
    padding: 10px;
    border-radius: 50%;
    color: $color-primary-inverse
}

// Resident Profile
#div-outbound-pin {
    margin-top: 7px;
}

// Account Details
#button-vacation-cancel {
    position: relative;
    top: -1px;
}

#vacation-message {
    margin-top: 5px;
    margin-bottom: 0;
}

.request-login-email {
    margin-top: 5px;
}

.cancel-btn-login-email {
    width: 90%;
    margin-left: 5px;
}
.btn-login-email {
    width: 90%;
    margin-right: 5px;
}

.input-email-certification {
    width: 100%;
}
